/*------------------------------------------------------------------
[Table of contents]

1. CSS transition (prefixed) / .transition 
2. CSS transform (prefixed) / .transform 
3. CSS transition with transform added (prefixed) / .transition-transform
4. CSS animation (prefixed) / .animation 
5. CSS transition-delay (prefixed) / .transition-delay 
6. CSS transform-origin (prefixed) / .transform-origin 

-------------------------------------------------------------------*/

.transition(@transition){
	-webkit-transition: @transition;
	-moz-transition: @transition;
	transition: @transition;
}

.transform(@transform){
	-webkit-transform: @transform;
	-o-transform: @transform;
	-ms-transform: @transform;
	transform: @transform;
}

.transition-transform(@transition){
	-webkit-transition: -webkit-transform @transition;
	-moz-transition: -moz-transform @transition;
	-o-transition: -o-transform @transition;
	transition: transform @transition;
}

.animation(@animation){
	-webkit-animation: @animation;
	-moz-animation: @animation;
	-ms-animation: @animation;
	-o-animation: @animation;
	animation: @animation;
}

.transition-delay(@delay){
	-webkit-transition-delay: @delay;
	-o-transition-delay: @delay;
	transition-delay: @delay;
}

.animation-delay(@delay){
	-webkit-animation-delay: @delay;
	-o-animation-delay: @delay;
	animation-delay: @delay;
}

.transform-origin(@origin){
	-webkit-transform-origin: @origin;
	transform-origin: @origin;
}